<template>
    <div>
        <custom-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </custom-button>
        <portal to="destination">
            <large-model v-if="displayModal" :submitting="submittingModal" :title="action.title" :additionalTitle="additionalTitle"
                   :confirmationString='`Are you sure you want to \"${action.title}\"`'
                   @removeFromDisplay="displayModal = false" @perform="perform()">

                <!-- Invoice File -->
                <component :is="getFieldComponent(mainFields.get('invoice_file'))" :field="mainFields.get('invoice_file')" v-model="formData[mainFields.get('invoice_file').name]">
                </component>
                <div v-show="errors.has(mainFields.get('invoice_file').name)" class="text-red-600">{{ errors.first(mainFields.get('invoice_file').name) }}</div>

                <!-- Invoice Reference -->
                <component :is="getFieldComponent(mainFields.get('invoice_reference'))" :field="mainFields.get('invoice_reference')" v-model="formData[mainFields.get('invoice_reference').name]">
                </component>
                <div v-show="errors.has(mainFields.get('invoice_reference').name)" class="text-red-600">{{ errors.first(mainFields.get('invoice_reference').name) }}</div>

                <!-- Tax Code -->
                <component v-if="mainFields.get('tax_code').type !== 'hidden'" @input="saveTaxPercent" :is="getFieldComponent(mainFields.get('tax_code'))" :field="mainFields.get('tax_code')" v-model="formData[mainFields.get('tax_code').name]">
                </component>
                <template v-else>
                    <radio-hidden-input :field="mainFields.get('tax_code')" v-model="formData[mainFields.get('tax_code').name]"></radio-hidden-input>
                </template>
                <div v-show="errors.has(mainFields.get('tax_code').name)" class="text-red-600">{{ errors.first(mainFields.get('tax_code').name) }}</div>

                <!-- Short Description -->
                <component v-if="mainFields.get('short_description').type !== 'hidden'" :is="getFieldComponent(mainFields.get('short_description'))" :field="mainFields.get('short_description')" v-model="formData[mainFields.get('short_description').name]">
                </component>
                <template v-else>
                    <radio-hidden-input :field="mainFields.get('short_description')" v-model="formData[mainFields.get('short_description').name]"></radio-hidden-input>
                </template>
                <div v-show="errors.has(mainFields.get('short_description').name)" class="text-red-600">{{ errors.first(mainFields.get('short_description').name) }}</div>

                <!-- Date -->
                <component :is="getFieldComponent(mainFields.get('date'))" :field="mainFields.get('date')" v-model="formData[mainFields.get('date').name]">
                </component>
                <div v-show="errors.has(mainFields.get('date').name)" class="text-red-600">{{ errors.first(mainFields.get('date').name) }}</div>

                <!-- Nominal Code -->
                <component v-if="mainFields.get('nominal_code').type !== 'hidden'" :is="getFieldComponent(mainFields.get('nominal_code'))" :field="mainFields.get('nominal_code')" v-model="formData[mainFields.get('nominal_code').name]">
                </component>
                <template v-else>
                    <radio-hidden-input :field="mainFields.get('nominal_code')" v-model="formData[mainFields.get('nominal_code').name]"></radio-hidden-input>
                </template>
                <div v-show="errors.has(mainFields.get('nominal_code').name)" class="text-red-600">{{ errors.first(mainFields.get('nominal_code').name) }}</div>

                <!-- Currency -->
                <template v-if="mainFields.get('currency')">
                    <component :is="getFieldComponent(mainFields.get('currency'))" :field="mainFields.get('currency')" v-model="formData[mainFields.get('currency').name]" @input="currencyChanged">
                    </component>
                    <div v-show="errors.has(mainFields.get('currency').name)" class="text-red-600">{{ errors.first(mainFields.get('currency').name) }}</div>
                </template>


                <div class="flex items-center justify-end">
                    <span class="ml-3"  @click="toggleTax()">
                        <span class="text-sm font-medium text-v3-gray-800 dark:text-v3-gray-200 mr-2">Auto Calculate Values </span>
                    </span>
                    <button type="button" :disabled="isCalculationDisabled" @click="toggleTax()" :class="{'bg-indigo-600' : calculateTaxOn, 'bg-gray-200': !calculateTaxOn}" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" aria-pressed="false" aria-labelledby="annual-billing-label">
                        <span class="sr-only">Use setting</span>
                        <span aria-hidden="true" :class="{'translate-x-5' : calculateTaxOn}" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                    </button>
                </div>

                <!-- Invoice Net Value -->
                <component class="flashable" :class="{'flash' : wasNetRecentlyAutomaticallyUpdated}" @blur="calculateValues('net')" :is="getFieldComponent(mainFields.get('invoice_net_value'))" :field="mainFields.get('invoice_net_value')" v-model="formData[mainFields.get('invoice_net_value').name]" :currencyType ="currencyType">
                </component>
                <div v-show="errors.has(mainFields.get('invoice_net_value').name)" class="text-red-600">{{ errors.first(mainFields.get('invoice_net_value').name) }}</div>

                <!-- Invoice Vat Value -->
                <component  class="flashable" :class="{'flash' : wasVatRecentlyAutomaticallyUpdated}" @blur="calculateValues('vat')" :is="getFieldComponent(mainFields.get('invoice_vat_value'))" :field="mainFields.get('invoice_vat_value')" v-model="formData[mainFields.get('invoice_vat_value').name]" :currencyType ="currencyType">
                </component>
                <div v-show="errors.has(mainFields.get('invoice_vat_value').name)" class="text-red-600">{{ errors.first(mainFields.get('invoice_vat_value').name) }}</div>

                <!-- Invoice Gross Value -->
                <component class="flashable" :class="{'flash' : wasGrossRecentlyAutomaticallyUpdated}" @blur="calculateValues('gross')" :is="getFieldComponent(mainFields.get('invoice_gross_value'))" :field="mainFields.get('invoice_gross_value')" v-model="formData[mainFields.get('invoice_gross_value').name]" :currencyType ="currencyType">
                </component>
                <div v-show="errors.has(mainFields.get('invoice_gross_value').name)" class="text-red-600">{{ errors.first(mainFields.get('invoice_gross_value').name) }}</div>

                <!-- Is Proforma Invoice -->
                <template v-if="mainFields.get('is_proforma_invoice')">
                    <component :is="getFieldComponent(mainFields.get('is_proforma_invoice'))" :field="mainFields.get('is_proforma_invoice')" v-model="formData[mainFields.get('is_proforma_invoice').name]" @input="proformaChanged">
                    </component>
                    <div v-show="errors.has(mainFields.get('is_proforma_invoice').name)" class="text-red-600">{{ errors.first(mainFields.get('is_proforma_invoice').name) }}</div>
                </template>
                <template v-if="mainFields.get('is_proforma_paid')">
                    <!-- Is Proforma Paid -->
                    <template v-if="isProforma === '1'">
                        <component :is="getFieldComponent(mainFields.get('is_proforma_paid'))" :field="mainFields.get('is_proforma_paid')" v-model="formData[mainFields.get('is_proforma_paid').name]">
                        </component>
                        <div v-show="errors.has(mainFields.get('is_proforma_paid').name)" class="text-red-600">{{ errors.first(mainFields.get('is_proforma_paid').name) }}</div>
                    </template>
                </template>
                <template v-slot:additional-fields>
                    <template v-for="(field, index) in additionalFields">
                        <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
                        </component>
                        <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
                    </template>

                    <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>
                </template>
            </large-model>
        </portal>
    </div>
</template>

<script>
    import ReconcileModal from '@/components/ReconcileModal';
    import ReconcileSlideOver from '@/components/ReconcileSlideOver';
    import RadioHiddenInput from "@/components/Action/Fields/RadioHiddenInput";
    import CustomButton from "@/v3/Buttons/CustomButton.vue";
    import LargeModel from "@/v3/models/LargeModel.vue";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                formData: {},
                taxPercent: null,
                calculateTaxOn: true,
                wasGrossRecentlyAutomaticallyUpdated: false,
                wasNetRecentlyAutomaticallyUpdated: false,
                wasVatRecentlyAutomaticallyUpdated: false,
                isCalculationDisabled: false,
                currencyType: 'GBP',
                isProforma: false
            }
        },
        components: {
            LargeModel,
            CustomButton,
            RadioHiddenInput,
            ReconcileModal,
            ReconcileSlideOver,
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            additionalButtonClasses: {
                type: String
            },
            buttonType: {
                type: String
            },
            additionalTitle:{
                type: String,
                default: null
            }

        },
        computed: {
            mainFields(){
                return this.action.fields;
            },
        },
        methods: {
            toggleTax(){
                if(!this.isCalculationDisabled) {
                    this.calculateTaxOn = !this.calculateTaxOn;
                }
            },
            saveTaxPercent(value){
                let taxFieldOptions = this.mainFields.get('tax_code').options
                if(taxFieldOptions.filter(option => option.value === value)[0]){
                    let taxFieldOptionsValues = taxFieldOptions.filter(option => option.value === value)[0].title.split('-');
                    this.taxPercent = parseFloat(taxFieldOptionsValues.pop().trim());
                }
            },
            calculateValues(type){
                if(this.taxPercent !== null && this.calculateTaxOn) {
                    if (type === 'net') {
                        this.$set(this.formData, 'invoice_vat_value', Math.round(this.formData.invoice_net_value * this.taxPercent));
                        this.$set(this.formData, 'invoice_gross_value', Math.round(this.formData.invoice_net_value + this.formData.invoice_vat_value));
                        this.wasGrossRecentlyAutomaticallyUpdated = true;
                        this.wasVatRecentlyAutomaticallyUpdated = true;
                        setTimeout(() => this.wasVatRecentlyAutomaticallyUpdated = false, 8000);
                        setTimeout(() => this.wasGrossRecentlyAutomaticallyUpdated = false, 8000);
                    }

                    if (type === 'gross') {
                        this.$set(this.formData, 'invoice_net_value', Math.round(this.formData.invoice_gross_value / (1 + this.taxPercent)));
                        this.$set(this.formData, 'invoice_vat_value', Math.round(this.formData.invoice_gross_value - this.formData.invoice_net_value));
                        this.wasNetRecentlyAutomaticallyUpdated = true;
                        this.wasVatRecentlyAutomaticallyUpdated = true;
                        setTimeout(() => this.wasVatRecentlyAutomaticallyUpdated = false, 8000);
                        setTimeout(() => this.wasNetRecentlyAutomaticallyUpdated = false, 8000);
                    }
                    if (type === 'vat') {
                        if (this.taxPercent === 0.2) {
                            this.$set(this.formData, 'invoice_net_value', Math.round(this.formData.invoice_vat_value * 5));
                            this.$set(this.formData, 'invoice_gross_value', Math.round(this.formData.invoice_net_value + this.formData.invoice_vat_value));
                            this.wasGrossRecentlyAutomaticallyUpdated = true;
                            this.wasNetRecentlyAutomaticallyUpdated = true;
                            setTimeout(() => this.wasNetRecentlyAutomaticallyUpdated = false, 8000);
                            setTimeout(() => this.wasGrossRecentlyAutomaticallyUpdated = false, 8000);
                        }
                    }
                }
            },
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                if(this.submittingModal === false) {
                    this.submittingModal = true;
                    this.action.perform(this.formData).then(res => {
                        this.displayModal = false;
                        this.submittingModal = false;
                        this.$store.dispatch(
                            "setMessage",
                            this.messages(res).entity.properties.get("success")
                        );
                        if (this.redirectOnSuccess) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                            this.$router.go(0);
                        }
                        this.$emit('success', res);
                    }).catch(error => {
                        this.submittingModal = false;
                        if(error.status !== 422) {
                            alert('There was an error processing your request.' + error);
                        }
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                        this.$emit('failure');
                    });
                }
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            currencyChanged(value){
                if(value === 'EUR'){
                    this.calculateTaxOn = false;
                    this.isCalculationDisabled = true;
                    this.currencyType = 'EUR';
                }else{
                    this.calculateTaxOn = true;
                    this.isCalculationDisabled = false;
                    this.currencyType = 'GBP';
                }
            },
            proformaChanged(value){
                this.isProforma = value;
            }
        },
        mounted() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                this.formData = payload;
                this.saveTaxPercent(parseInt(this.formData[this.mainFields.get('tax_code').name]));
                if(this.mainFields.get('currency')){
                    this.currencyChanged(this.formData[this.mainFields.get('currency').name]);
                }
                if(this.mainFields.get('is_proforma_invoice')) {
                    console.log(this.formData[this.mainFields.get('is_proforma_invoice').name]);
                    this.isProforma = this.formData[this.mainFields.get('is_proforma_invoice').name];
                }
        }

    }
</script>

<style lang="scss">
    .flashable input{
        box-shadow: 0 0 0 #ddd;
    }
    .flash input{
        transition: box-shadow 1s;
        box-shadow: 0 0 8px orange;
    }
</style>
